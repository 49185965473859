@charset 'utf-8';
/* ------------------------------------------------
PolicyEditPage
------------------------------------------------ */
.policyEdit {
  margin-top: 60px;
  &_title {
    padding: 15px 20px;
    background: linear-gradient(
      270deg,
      #fff 99.2%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
    > p {
      color: var(--kanri-secondary-text, #595959);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }
  &_formWrapper {
    width: 1020px;
    border-radius: 6px;
    background: #fff;
    padding: 20px 20px 40px;
    margin: 20px 20px 40px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
    > p {
      color: var(--kanri-secondary-text, #595959);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }
  &_form {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    margin: 40px 20px 0;
    &_default,
    &_withLabel {
      > span {
        font-size: 14px;
        font-style: normal;
        line-height: 100%;
        color: var(--kanri-secondary-text, #595959);
      }
      &_label {
        font-weight: 700;
        margin-right: 10px;
      }
      &_annotation {
        font-weight: 400;
      }
      &_content {
        display: flex;
        gap: 0 5px;
        > div {
          flex-shrink: 0;
        }
      }
    }
    &_withLabel {
      position: relative;
      &_label {
        position: absolute;
        top: 0;
        left: 16px;
        padding: 0 1px;
        z-index: 1;
        background-color: #fff;
      }
    }
  }
  &_typeChangeWrapper {
    display: flex;
    width: 980px;
    margin-top: 40px;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dddddd;

    > span {
      font-size: 14px;
      color: #595959;
    }
  }
  .policyEdit_formButton {
    width: 300px;
    height: 51px;
    margin: 20px auto 0;
  }
}

@charset 'utf-8';
/* ------------------------------------------------
@import.scss
------------------------------------------------ */

* {
  font-family: 'Meiryo', 'メイリオ', 'Hiragino Sans', sans-serif;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  .fill {
    fill: var(--kanri-secondary-text);
  }
}
:root {
  --white: #fff;
  --kanri-primary-text: #333;
  --kanri-secondary-text: #595959;
  --kanri-sub-text: #777;
  --kanri-primary: #3e91d5;
  --kanri-primary-hover: #ecf9ff; //実装で追加
  --kanri-blue: #1a86d1;
  --kanri-bg-02: #e1e4ed;
  --kanri-gray: #9fb2bf;
  --kanri-bg-03: #ecf9ff;
  --kanri-bg-01: #f2f3f7;
  --kanri-modal: rgba(0, 0, 0, 0.8);
  --kanri-erroralert: #dc1357;
  --kanri-link: #0e7ead;
  --kanri-border-border-3: #cccccc;

  --kanri-disabled-button-color: #dddddd;
  --kanri-disabled-button-text-color: #777777;
  --kanri-disabled-button-text-color_secondary: #cccccc;

  /* 選択中の色 */
  --kanri-selected-key-color: #005497;
  --kanri-selected-sub-color: #e2effa;
  --kanri-selected-background-color: #ffffff;
  --kanri-selected-text-color: #ffffff;
  --kanri-selected-subbackground-color: #ffffff;

  /* テーマに合わせたキーカラー */
  --kanri-gray-key-color: #757575;
  --kanri-orange-key-color: #ce4b01;
  --kanri-red-key-color: #d62c2c;
  --kanri-green-key-color: #327650;
  --kanri-blue-key-color: #005497;
  --kanri-purple-key-color: #5755b0;

  --kanri-edit-min-width: 980px;
}

body {
  background-color: var(--kanri-bg-01, #f2f3f7);
  min-height: 100vh;
  line-height: inherit;
  margin: 0;
}

/* reset css */
img {
  max-width: 100%;
  height: auto;
  display: block;
}
a {
  color: inherit;
  text-decoration: inherit;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  pointer-events: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

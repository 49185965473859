@charset 'utf-8';
/* ------------------------------------------------
Status
------------------------------------------------ */
.top_status {
  &_base {
    padding: 0;
  }
  &_card {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
    display: grid;
    gap: 10px;
  }
  &_openStatus {
    &_base {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
    &_title {
      padding: 0 8px;
      border-radius: 4px;
      background: #eee;
      text-align: center;
      color: var(--kanri-primary-text, #333);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      width: 200px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_sign {
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      align-items: flex-end;
      min-width: 500px;
    }
    &_message {
      color: var(--kanri-secondary-text, #595959);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }
    &_edit {
      color: var(--kanri-link, #0e7ead);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      padding-left: 2px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.IconSelectDialog {
  .icons-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    .icon-wrap {
      border-radius: 8px;
      border: 1px solid var(--kanri-gray-01, #ddd);
      background: #fff;
      padding: 1px;
      &.checked {
        border: 2px solid var(--kanri-gray, #9fb2bf);
        padding: 0px;
      }
      &:hover {
        border: 2px solid var(--kanri-gray, #9fb2bf);
        padding: 0px;
        .MuiRadio-root:before {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 65%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: var(--kanri-secondary-text, #595959);
        }
        .MuiRadio-colorSecondary.Mui-checked:hover:before {
          background-color: transparent;
        }
      }
    }
  }
  &.disabled {
    .MuiFormControlLabel-root {
      background: var(--kanri-gray-01, #ddd);
    }
  }
}

.InitialSettingDialog {
  .dialog-wrap {
    padding: 40px;
    .dialog-header-wrap {
      .dialog-title-wrap {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .dialog-title {
          color: var(--kanri-primary-text, #333);
          /* large/normal-100 */
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 24px */
          letter-spacing: -1.2px;
          display: inline-block;
          margin-right: 20px;
        }
        .dialog-title-subtext {
          color: var(--kanri-sub-text, #777);
          display: inline-block;
        }
        .dialog-title-supplement {
          color: var(--kanri-primary-text, #333);
          /* x-small/normal */
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 15.6px */
        }
      }
    }
    .dialog-content-wrap {
      display: flex;
      gap: 20px;
      flex-direction: column;
      &.basic {
        padding-left: 15px;
        padding-right: 20px;
      }
      &.design {
        padding-left: 10px;
      }

      .input-content-wrap {
        .input-content-title-wrap {
          .input-content-title {
            color: var(--kanri-secondary-text, #595959);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            margin-right: 20px;
          }
          .input-content-title-sub {
            color: var(--kanri-secondary-text, #595959);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            &.error {
              color: var(--kanri-erroralert, #dc1357);
            }
          }
        }
        input {
          height: 18px;
        }
        .MuiInputBase-root {
          padding: 14px 16px;
        }
        &.occupation {
          .MuiChip-root {
            height: 28px;
            border: 1px solid var(--kanri-gray-02, #ccc);
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
          }
          .MuiAutocomplete-inputRoot {
            gap: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
            input {
              height: 32px;
            }
          }
          .Mui-focused {
            font-weight: normal;
          }
        }
        .MuiAutocomplete-endAdornment {
          top: unset;
        }
      }
      .design-wrap {
        .alert-on {
          .design-select-text,
          .color-select-text {
            &.invalid {
              &:after {
                content: '選択してください';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                color: var(--kanri-erroralert, #dc1357);
              }
              & ~ .DesignSelectImage .MuiFormControlLabel-root {
                border: 2px solid var(--kanri-erroralert, #dc1357);
                padding: 3px 4.5px;
              }
            }
          }
        }
        .DesignSelectImage {
          width: fit-content;
          margin-left: 0;
          margin-right: auto;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;
          .MuiFormControlLabel-root {
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
            padding: 5px 6.5px;
            animation: none;
            transform: none;
            transition: unset;
            &:hover {
              padding: 3px 4.5px;
              border: 2px solid var(--kanri-gray-03, #899194);
            }
          }
          .radioChoiceButton {
            background-color: transparent !important;
            box-shadow: none !important;
            color: var(--kanri-sub-text) !important;
            font-size: 14px;
            svg {
              fill: var(--kanri-disabled-button-color) !important;
              width: 14px !important;
              height: 14px !important;
            }
            &.checked {
              svg {
                fill: var(--kanri-secondary-text) !important;
              }
            }
          }
        }
        .DesignSelectColor {
          display: flex;
          gap: 5px;
          flex-direction: row;
          label {
            width: unset !important;
            .MuiTypography-root {
              display: flex;
              .radio-button-color-area {
                margin-top: 0 !important;
                width: 40px !important;
                height: 40px !important;
                svg {
                  display: block !important;
                  fill: transparent;
                }
              }
              .radioChoiceButton {
                white-space: nowrap;
                width: fit-content !important;
                margin-top: 0;
                background: transparent !important;
                color: var(--kanri-sub-text) !important;
                box-shadow: none !important;
                padding-right: 10px;
                padding-left: 10px;
                svg {
                  display: none;
                }
              }
            }
          }
        }
      }
      .error-content-wrap {
        display: flex;
        gap: 40px;
        flex-direction: column;
        align-items: center;
        color: var(--kanri-primary-text);
        font-style: normal;
        margin: 40px 40px 0 40px;
        .error-icon {
          width: 104px;
          height: 90px;
          fill: #dc1357;
        }
        .error-head {
          font-size: 32px;
          font-weight: 700;
          line-height: 100%;
        }
        .error-detail {
          font-size: 16px;
          font-weight: 400;
          line-height: 160%;
          .MuiLink-root {
            color: var(--kanri-link);
            font-size: 16px;
            font-weight: 400;
            line-height: 160%;
            text-decoration-line: underline;
          }
        }
      }
    }
    .button-wrap {
      height: 78px;
      display: flex;
      align-items: flex-end;
      &.justify-end {
        justify-content: flex-end;
      }
      &.justify-between {
        justify-content: space-between;
      }
      &.justify-center {
        justify-content: center;
      }
    }
  }
}

@charset 'utf-8';
/* ------------------------------------------------
customizedAccordion
------------------------------------------------ */
.customizedAccordion {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  &_category {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
  }
  &_detail {
    color: var(--kanri-secondary-text, #595959);
    font-size: 14px;
    white-space: pre-line;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

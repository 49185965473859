@charset 'utf-8';
/* ------------------------------------------------
settingCard
------------------------------------------------ */
.top_settingCard {
  &_base {
    display: flex;
    padding: 15px 15px 20px 15px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    &:first-of-type {
      .top_settingCard_image {
        height: 71px;
      }
    }
    &:nth-of-type(2) {
      .top_settingCard_image {
        height: 76px;
      }
    }
    &:nth-of-type(3) {
      .top_settingCard_imageBase {
        margin: -4px 0 18px;
      }
      .top_settingCard_image {
        height: 92px;
      }
    }
    &:nth-of-type(4) {
      .top_settingCard_imageBase {
        margin: -4px 0 18px;
      }
      .top_settingCard_image {
        height: 83px;
      }
    }
  }
  &_title {
    color: var(--kanri-secondary-text, #595959);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
  &_imageBase {
    margin: 8px 0 6px;
    max-height: 80px;
  }
  &_image {
    width: auto;
  }
  &_description {
    color: var(--kanri-secondary-text, #595959);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    flex-grow: 1;
  }
  &_button {
    width: 100%;
    height: 37px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 8px;
    background: var(--kanri-primary, #3e91d5);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
  }
}

.StatusText {
  .status {
    &-green,
    &-limited,
    &-red {
      font-size: 12px;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.25) inset;
        margin-right: 4px;
      }
    }
    &-green:before {
      background-color: #21a72f;
    }
    &-limited:before {
      background-color: #f8b405;
    }
    &-red:before {
      background-color: #dc2121;
    }
  }
}

#editHeaderMenu_AppBar {
  min-width: var(--kanri-edit-min-width);
  height: 60px;
  z-index: 2;
  .edit-header-wrap {
    .edit-header-contents-wrap {
      display: flex;

      .edit-header-contents {
        display: flex;
      }
      @media screen and (max-width: 980px) {
        justify-content: flex-end;
        .edit-header-contents {
          overflow-x: hidden;
          .MuiTabs-root {
            overflow: unset;
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}

@charset 'utf-8';
/* ------------------------------------------------
Status
------------------------------------------------ */
.top_status {
  &_openUrl {
    &_base {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
    &_titleBase {
      padding: 4px 8px;
      border-radius: 4px;
      background: #eee;
      text-align: center;
      color: var(--primary-primary-800, #0b1020);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      width: 200px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: 4px;
      }
    }
    &_urlBase {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
    }
    &_url {
      color: var(--kanri-primary-text, #333);
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      overflow: hidden;
      &.disabled {
        color: var(--kanri-disabled-button-text-color_secondary);
      }
    }
    &_copyButton {
      width: 49px;
      height: 30px;
    }
    &_newWindow {
      &_icon {
        width: 16px;
        height: 16px;
        path {
          fill: #3e91d5;
        }
      }
    }
  }
}

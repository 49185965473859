@charset 'utf-8';
/* ------------------------------------------------
Policy
------------------------------------------------ */
.policy {
  &_content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 960px;
    margin: 0 auto;
    padding-top: 100px;
    &_service {
      margin-bottom: 40px;
    }
    &_primaryText {
      white-space: pre-line;
      margin-bottom: 20px;
    }
    &_secondaryText {
      color: var(--kanri-secondary-text, #595959);
      margin-bottom: 0;
    }
    &_link {
      display: inline-flex;
      gap: 5px;
      height: 18px;
      margin-bottom: 20px;
      color: var(--kanri-link, #0e7ead);
      align-items: center;
    }
    .fill {
      fill: var(--kanri-link, #0e7ead);
    }
  }
}

@charset 'utf-8';
/* ------------------------------------------------
@import.scss
------------------------------------------------ */
.wrapperBox {
  display: flex;
}

.absoluteScreen {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100vh;
}

.top_menu_help_icon {
  svg {
    width: 20px;
    height: 20px;
    background-color: #595959;
    border-radius: 100%;
    padding: 3px;
    cursor: pointer;
  }
  &:hover {
    svg {
      background-color: #3e91d5;
      path {
        fill: #ffffff !important;
      }
    }
  }
}

.footer {
  $root: &;
  &._commonPage {
    text-align: center;
    padding: 30px 0;
    color: var(--kanri-secondary-text, #595959);
    font-size: 10px;
    font-weight: 400;
    margin-top: auto;
  }
  &._logoutPage,
  &._notFoundPage {
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    position: fixed;
    left: 50%;
    margin-left: -50%;
    width: 100%;
    bottom: 0px;
    dl {
      display: flex;
      padding: 10px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      dt {
        color: var(--kanri-secondary-text, #595959);
        font-size: 10px;
        font-style: normal;
        font-weight: 400px;
        line-height: 100%;
        letter-spacing: -0.3;
      }
      dd {
        color: #757575;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500px;
        line-height: 150%;
        letter-spacing: -0.132;
      }
    }
  }
}

.ImageRadioButtons {
  margin-top: 10px;
  overflow-y: auto;
  height: 407px;
  .icons-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    .icon-wrap {
      border-radius: 8px;
      border: 1px solid var(--kanri-gray-01, #ddd);
      background: #fff;
      padding: 1px;
      .MuiFormControlLabel-root {
        padding: 15px 15px 20px 15px;
        border-radius: 8px;
        .MuiRadio-root {
          padding: 0;
          margin-bottom: 10px;
        }
        .MuiTypography-root {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 150px;
            height: 100px;
          }
          span {
            overflow: hidden;
            color: var(--kanri-secondary-text, #595959);
            text-overflow: ellipsis;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            display: inline-block;
            width: 170px;
            margin-top: 10px;
          }
        }
      }

      &.checked {
        border: 2px solid var(--kanri-gray, #9fb2bf);
        padding: 0px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      }
      &:hover {
        border: 2px solid var(--kanri-gray, #9fb2bf);
        padding: 0px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);

        .MuiRadio-colorSecondary.Mui-checked:hover:before {
          background-color: transparent;
        }
      }
    }
  }
}

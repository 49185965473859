#editHeaderMenu_StatusBar {
  height: 32px;
  top: 60px;
  left: 0;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding-right: 20px;
  min-width: var(--kanri-edit-min-width);
  z-index: 1;
  .editHeaderMenu_StatusBar-wrap {
    position: relative;
    width: 100%;

    .editHeaderMenu_StatusBar-contents-wrap {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      height: 32px;
      width: 100%;
      justify-content: flex-end;
      .slider-wrap {
        display: flex;
        align-items: center;
        height: 32px;
        .slider-value {
          width: 30px;
          text-align: right;
          font-size: 11px;
          color: var(--kanri-primary-text, #333);
          font-weight: 700;
          letter-spacing: -0.55px;
          display: inline-block;
          margin-left: 10px;
        }
      }

      .information-wrap {
        width: 405px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
        .status-text-wrap {
          margin-right: 10px;
          font-size: 11px;
          display: flex;
          align-items: center;
          margin-right: 10px;
          .StatusText {
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
          }
        }
        .timestamp-text {
          font-size: 12px;
          display: inline-block;
        }
      }

      @media screen and (max-width: 980px) {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

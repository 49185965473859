.progresbar {
  width: 720px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px auto;

  .step-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .step-text,
    .step-supplement {
      color: var(--kanri-gray-03, #899194);
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      display: inline-block;
      height: 12px;

      &.step-text {
        margin-bottom: 4px;
      }
      &.step-supplement {
        margin-top: 4px;
      }
    }
    .step-number {
      background-color: var(--kanri-bg-02, #e1e4ed);
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 14px;
      z-index: 1;

      color: var(--kanri-sub-text, #777);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    /* 線を追加 */
    &::after {
      content: '';
      height: 2px;
      background-color: var(--kanri-bg-02, #e1e4ed);
      position: absolute;
      left: 50%;
      width: 330px;
      top: 50%;
      transform: translateY(-50%);
    }

    /* 最後の要素の線は不要なので非表示にする */
    &:last-child::after {
      display: none;
    }

    &.active {
      .step-text,
      .step-supplement {
        color: var(--kanri-primary, #3e91d5);
      }
      .step-number {
        color: white;
        background-color: var(--kanri-primary, #3e91d5);
      }
      &::after {
        background-color: var(--kanri-primary, #3e91d5);
      }
    }

    &.checked {
      .step-number {
        background-color: transparent;
        svg {
          width: 28px;
          height: 28px;
          fill: var(--kanri-sub-text, #777);
        }
      }
      &::after {
        left: 80%;
        background-color: var(--kanri-sub-text, #777);
      }
    }
  }
}

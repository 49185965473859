.CustomSelectDialog {
  .dialog-title-wrap {
    display: flex;
    justify-content: space-between;
    .dialog-title {
      display: inline-block;
      color: var(--kanri-primary-text, #333);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -1.2px;
    }
    .MuiButton-text {
      &.Mui-disabled {
        color: var(--kanri-sub-text, #777);
      }
    }
    .dialog-title-icon {
      &.dialog-title-icon-error {
        svg {
          fill: var(--kanri-sub-text, #777);
        }
      }
    }
  }
}

#EditSaiyouPageContent {
  overflow-y: hidden;
  .main-contents-wrap {
    transition-property: left, width;
    position: absolute;
    overflow: hidden;
    height: 100%;
    .main-contents-scroll-wrap {
      overflow-x: hidden;
      overflow-y: hidden;
      height: 100%;
      .main-contents {
        margin: 0 auto;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        height: 100%;
        position: relative;
        overflow: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .main-contents-help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}

@charset 'utf-8';
/* ------------------------------------------------
Frame
------------------------------------------------ */
._commonFrame {
  &_title {
    color: var(--kanri-primary-text, #333);
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 10px;
    &::after {
      content: attr(data-subtitle);
      font-size: 12px;
      line-height: 1.3;
      margin-left: 20px;
    }
    &_helpManual,
    &_helpFaq {
      @extend ._commonFrame_title;
      margin-bottom: 20px;
    }
  }
  &_contents {
    min-height: 81px;
    color: var(--kanri-primary-text, #333);
    font-size: 14px;
    line-height: 1.6;
    padding: 20px 20px 0;
    margin-bottom: 40px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &_helpManual {
      @extend ._commonFrame_contents;
      margin-bottom: 10px;
    }
    &_helpFaq {
      @extend ._commonFrame_contents;
    }
  }
}

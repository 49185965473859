.LazyIcon {
  .svg-icon {
    width: 106px !important;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100px;
      height: 100px;

      .line {
        fill: var(--kanri-selected-key-color);
      }
      .color01 {
        fill: var(--kanri-selected-sub-color);
      }
      .color02 {
        fill: var(--kanri-selected-background-color);
      }
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 106px;
    width: 106px;
    position: relative;
  }
}

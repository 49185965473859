._closeButton{
    display: block;
    width: 150px;
    height: 45px;
    margin: 0 auto;
    padding: 8px 16px;
    color: var( --kanri-secondary-text, #595959);
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    border: 1px solid #CCC;
    background: var( --white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20);
    flex-shrink: 0;
    &:hover{
        background: rgba(255, 255, 255, 0.85);
        cursor: pointer;
    }
}
@charset 'utf-8';
/* ------------------------------------------------
TopPage
------------------------------------------------ */
.top {
  &_wrap {
    padding-top: 60px;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    grid-template-columns: 100%;
    min-height: 100vh;
    footer {
      width: 100%;
    }
  }
  &_content {
    padding: 15px 20px 20px;
    &_link {
      text-decoration: underline;
    }
    .StatusText {
      .status {
        &-green,
        &-limited,
        &-red {
          color: var(--kanri-secondary-text);
          font-size: 16px !important;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
          &:before {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &_settings {
    &_base {
      padding-top: 15px;
    }
    &_title {
      &_base {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
      }
      &_title {
        color: var(--kanri-secondary-text, #595959);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }
      &_description {
        color: var(--kanri-secondary-text, #595959);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }
    }
    &_panel {
      margin-top: 10px;
      &_base {
        display: grid;
        padding-top: 5px;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        @media screen and (min-width: 1289px) {
          grid-template-columns: repeat(
            auto-fill,
            minmax(calc((100% - 30px) / 4), 1fr)
          );
        }
      }
    }
  }
}

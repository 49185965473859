@charset 'utf-8';
/* ------------------------------------------------
Help
------------------------------------------------ */
.help {
  &_content {
    display: flex;
    flex-direction: column;
    width: 960px;
    margin: 0 auto;
    padding-top: 100px;
    &_helpManual {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;
      &_title {
        display: flex;
        width: 270px;
        color: var(--kanri-primary-text, #333);
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        &:after {
          content: '';
          width: 1px;
          height: 44px;
          background: linear-gradient(
            180deg,
            rgba(217, 217, 217, 0) 0%,
            #d9d9d9 27.6%,
            rgba(217, 217, 217, 0.87) 76.04%,
            rgba(217, 217, 217, 0) 100%
          );
        }
      }
      &_body {
        max-width: 465px;
        color: var(--kanri-secondary-text, #595959);
        font-size: 14px;
        line-height: 1.6;
        word-wrap: break-word;
        word-break: keep-all;
        overflow-wrap: break-word;
        &_notes {
          display: block;
          color: var(--kanri-sub-text, #777);
          font-size: 12px;
          margin-top: 3px;
        }
      }
    }
    &_pdf {
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: flex-end;
      align-items: center;
      color: var(--kanri-primary-text, #333);
      font-size: 14px;
      line-height: 1;
      margin-right: 20px;
      margin-bottom: 30px;
      &_link {
        display: flex;
        flex-direction: row;
        gap: 4px;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
        text-decoration: underline;
        .fill {
          fill: #fff;
        }
      }
    }
    &_helpFaq {
      margin-bottom: 20px;
      &_title {
        color: var(--kanri-primary-text, #333);
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        margin: 40px 0 20px;
      }
      &_list {
        list-style-type: disc;
        list-style: inside;
        color: var(--kanri-link, #0e7ead);
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &_link {
          color: var(--kanri-link, #0e7ead);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          text-decoration-line: underline;
        }
      }
    }
  }
}

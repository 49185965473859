@charset 'utf-8';
/* ------------------------------------------------
cacheClear
------------------------------------------------ */
.top_cacheClear {
  &_base {
    display: flex;
    padding-top: 40px;
    padding-right: 20px;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
  }
  &_button {
    width: 152px;
    height: 36px;
    display: flex;
    gap: 4px;
    align-items: center;
    background-color: inherit;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
  }
  &_icon {
    width: 20px;
    height: 20px;
  }
  &_title {
    color: var(--kanri-primary-text, #333);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

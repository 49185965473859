.ImageUploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ImageChange {
  .MuiDialogContent-root {
    padding-bottom: 0 !important;
  }
  padding-bottom: 5px;
  .mode-change-buttons {
    border-bottom: 1px solid var(--kanri-gray-02, #ccc);
    width: 100%;
    .mode-change-buttons-wrap {
      border-bottom: 1px solid var(--kanri-gray-02, #ccc);
      display: flex;
      width: fit-content;
      .divider {
        width: 1px;
        background: linear-gradient(
          180deg,
          rgba(204, 204, 204, 0) 0%,
          #ccc 30.21%,
          #ccc 80.21%,
          rgba(204, 204, 204, 0) 100%
        );
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .dialog-title-wrap {
      button {
        width: 120px;
        font-size: 12px;
      }
    }
    .ImageDropzone {
      p {
        font-size: 14px;
      }
    }
    .image-select-wrap {
      width: 100%;
      .image-select-inner {
        display: block;
        .sp-br {
          display: block;
          margin-bottom: 5px;
        }
        & ~ ul {
          padding-left: 0;
          li {
            font-size: 10px;
          }
        }
        .MuiFileInput-placeholder {
          font-size: 10px;
        }
        .MuiButton-outlinedSizeMedium {
          font-size: 10px;
          padding: 5px;
          min-width: 73px;
        }
      }
    }
    .mode-change-buttons {
      margin-top: 10px;
      .divider {
        width: 2px;
      }
    }
    .baitoru-management-image {
      .ImageRadioButtons {
        max-height: calc(100vh - 340px) !important;
      }
      .baitoru-management-image-search {
        .MuiButtonBase-root {
          width: 80px;
        }
      }
    }
    .free {
      .ImageRadioButtons {
        max-height: calc(100vh - 300px);
      }
    }
  }
  @media screen and (max-width: 1289px) {
    .baitoru-management-image {
      .baitoru-management-image-search {
        .MuiOutlinedInput-root {
          width: 100%;
        }
      }
    }
  }

  .image-upload {
    overflow: auto;
    width: 100%;
    height: 447px;
    max-height: calc(100vh - 300px);
  }

  .baitoru-management-image {
    width: 100%;
    .baitoru-management-image-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .ImageRadioButtons {
      height: 387px;
      max-height: calc(100vh - 322px);
    }
  }
  .free {
    width: 100%;
    .ImageRadioButtons {
      height: 447px;
      max-height: calc(100vh - 300px);
      img {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
